import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-navigation-buttons',
  templateUrl: './slider-navigation-buttons.component.html',
  styleUrls: ['./slider-navigation-buttons.component.scss']
})
export class SliderNavigationButtonsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
