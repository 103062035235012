<div class="slide">
    <div class="photo-wrapper">
        <div class="photo">
            <a routerLink="/adverts/some-estate">
                <img src="https://via.placeholder.com/500/350" class="img-fluid" alt="photo placeholder">
            </a>
        </div>
        <div class="overlay">
            <div class="quick-view" [class.moved]="quickViewActive" (click)="toggleQuickView()">
                <p *ngIf="!quickViewActive">Greita peržiūra →</p>
                <p *ngIf="quickViewActive"><a routerLink="/adverts/some-estate">Žiūrėti skelbimą →</a></p>
            </div>
            <div class="favorite">
                <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Heart</title><path d='M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='20'/></svg>
            </div>
            <div class="main-info" [class.active]="quickViewActive">
                <div class="top">
                    <h3>Pavadinimas susideda iš penkių ar daugiau žodžių!</h3>
                    <p class="address"><i class="fas fa-map-marker-alt"></i> Kauno r., Žiegždrionys</p>
                    <ul>
                        <li><span class="relt-icon-blueprint1"></span> 55 m<sup>2</sup></li>
                        <li><span class="relt-icon-blueprint2"></span>5 kambariai</li>
                    </ul>
                </div>
                <div class="bottom">
                    <p class="price">130 000 Eur.</p>
                </div>
            </div>
        </div>
    </div>
</div>
