<div class="info-page content-wrapper buildings-bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-3 search-box-wrapper">
                <div class="search-box">
                    <h4>Detali paieška:</h4>
                    <div class="inputs">
                        <div class="input-group">
                            <label for="">Laukelis:</label>
                            <input type="text" id="" placeholder="Laukelio info">
                        </div>
                        <div class="input-group">
                            <label for="">Laukelis:</label>
                            <input type="text" id="" placeholder="Laukelio info">
                        </div>
                        <div class="input-group">
                            <label for="">Laukelis:</label>
                            <input type="text" id="" placeholder="Laukelio info">
                        </div>
                        <div class="input-group">
                            <label for="">Laukelis:</label>
                            <input type="text" id="" placeholder="Laukelio info">
                        </div>
                        <div class="input-group">
                            <label for="">Laukelis:</label>
                            <input type="text" id="" placeholder="Laukelio info">
                        </div>
                        <div class="input-group">
                            <label for="">Laukelis:</label>
                            <input type="text" id="" placeholder="Laukelio info">
                        </div>
                    </div>
                    <div class="button-wrapper">
                        <a routerLink="#" class="button">Ieškoti</a>
                        <a routerLink="#" class="search-type-toggler">greitoji paieška</a>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-8 offset-lg-1 results-wrapper">
                <div class="top-info">
                    <div class="count">
                        <h4>Rezultatai</h4>
                        <p class="found">(rasta: 187)</p>
                    </div>
                    <div class="sorting">
                        <p class="sorting-method">Rikiuoti pagal:</p>
                        <input type="text" placeholder="žemiausia kaina">
                    </div>
                    <div class="view-mode">
                        <div class="icon active"><i class="fas fa-bars"></i></div>
                        <div class="icon"><i class="fas fa-th-large"></i></div>
                    </div>
                </div>
                <div class="results">
                    <app-advert-box1></app-advert-box1>
                    <app-advert-box1></app-advert-box1>
                    <app-advert-box1></app-advert-box1>
                    <app-advert-box1></app-advert-box1>
                    <app-advert-box1></app-advert-box1>
                    <app-advert-box1></app-advert-box1>
                    <app-advert-box1></app-advert-box1>
                    <app-advert-box1></app-advert-box1>
                    <app-advert-box1></app-advert-box1>
                </div>
            </div>
        </div>
        <div class="row pagination">
            <div class="col-12 buttons">
                <button class="prev">
                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Arrow Back</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/></svg>
                </button>
                <ul class="pages">
                    <li><button>1</button></li>
                    <li><button class="active">2</button></li>
                    <li><button>3</button></li>
                </ul>
                <button class="next">
                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Arrow Forward</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/></svg>
                </button>
            </div>
        </div>
    </div>
</div>
