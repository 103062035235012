<header class="information-page-header" style="--background: url('/assets/img/pages/services/service1/service-header.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Nekilnojamo turto vertinimas</h1>
            </div>
        </div>
    </div>
</header>

<div class="info-page content-wrapper buildings-bottom services-page">
    <div class="container">
        <div class="row about-service">
            <div class="col-12">
                <p class="pre-title">Mūsų siūlomos paslaugos</p>
            </div>
            <div class="col-12">
                <h2 class="additional-title">Nekilnojamo turto vertinimas</h2>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-12">
                <p class="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita.</p>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-12 offset-xl-1 offset-lg-0">
                <ul class="listed-info">
                    <li>
                        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Checkmark</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M416 128L192 384l-96-96'/></svg>
                        Lorem ipsum dolor sit esse amet.
                    </li>
                    <li>
                        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Checkmark</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M416 128L192 384l-96-96'/></svg>
                        Et esse incidunt Lorem, ipsum dolor eaque quis?
                    </li>
                    <li>
                        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Checkmark</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M416 128L192 384l-96-96'/></svg>
                        Dolor quasi odit incidunt quia quam.
                    </li>
                </ul>
            </div>
        </div>
        <div class="row why-us">
            <div class="col-12">
                <h2 class="block-title">Kodėl verta mus rinktis?</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <div class="why-block">
                    <div class="icon">
                        <span class="relt-icon-house-4"></span>
                    </div>
                    <h3>Patogu ir kokybiška</h3>
                    <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore debitis cupiditate repellendus delectus molestiae consequuntur?</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <div class="why-block">
                    <div class="icon">
                        <span class="relt-icon-mortgage-1"></span>
                    </div>
                    <h3>Greita ir efektyvu</h3>
                    <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore debitis cupiditate repellendus delectus molestiae consequuntur?</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12">
                <div class="why-block">
                    <div class="icon">
                        <span class="relt-icon-agent2"></span>
                    </div>
                    <h3>Naudinga ir vertinga</h3>
                    <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore debitis cupiditate repellendus delectus molestiae consequuntur?</p>
                </div>
            </div>
        </div>
        <div class="row process">
            <div class="col-12">
                <h2 class="block-title">Kaip tai atliekama?</h2>
            </div>
            <div class="col-12 process-steps">
                <div class="row step">
                    <div class="col-lg-6 col-md-12">
                        <div class="image">
                            <img src="https://via.placeholder.com/800/500" class="img-fluid" alt="placeholder image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 info">
                        <h3>Pirmas žingsnis: analizuojame</h3>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.</p>
                        <ul>
                            <li>Lorem ipsum dolor sit amet consectetur.</li>
                            <li>Beatae qui ipsam explicabo doloremque illum?</li>
                            <li>Est vero consequuntur illo dolores quo.</li>
                        </ul>
                    </div>
                </div>
                <div class="row step">
                    <div class="col-lg-6 col-md-12">
                        <div class="image">
                            <img src="https://via.placeholder.com/800/500" class="img-fluid" alt="placeholder image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 info">
                        <h3>Antras žingsnis: vertiname</h3>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.</p>
                        <ul>
                            <li>Lorem ipsum dolor sit amet consectetur.</li>
                            <li>Beatae qui ipsam explicabo doloremque illum?</li>
                            <li>Est vero consequuntur illo dolores quo.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row services-block">
            <div class="col-12">
                <h2 class="block-title">Kitos mūsų siūlomos paslaugos</h2>
            </div>
            <div class="col-lg-6 col-md-12 service-block">
                <div class="service">
                    <div class="image">
                        <a routerLink="/services/some-service">
                            <img src="/assets/img/pages/services/service2.jpg" class="img-fluid" alt="image placeholder">
                        </a>
                    </div>
                    <div class="info">
                        <h3><a routerLink="/services/some-service">Dokumentų ruošimas</a></h3>
                        <p class="description">Dolor consectetur adipisicing consectetur sit amet nostrum cum quisquam consectetur adipisicing elit. Qui sit, nostrum cum quisquam dolorem enim!</p>
                        <p class="read-more"><a routerLink="/services/some-service">skaityti daugiau</a></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 service-block">
                <div class="service">
                    <div class="image">
                        <a routerLink="/services/some-service">
                            <img src="/assets/img/pages/services/service3.jpg" class="img-fluid" alt="image placeholder">
                        </a>
                    </div>
                    <div class="info">
                        <h3><a routerLink="/services/some-service">Konsultavimas</a></h3>
                        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui sit, nostrum cum quisquam dolorem enim!</p>
                        <p class="read-more"><a routerLink="/services/some-service">skaityti daugiau</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
