import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-partners',
    templateUrl: './home-partners.component.html',
    styleUrls: ['./home-partners.component.scss']
})
export class HomePartnersComponent implements OnInit {

    partners = [1, 2, 3, 4];

    constructor() { }

    ngOnInit(): void {
    }

}
