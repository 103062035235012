import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsPostComponent } from './pages/news/news-post/news-post.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ServicesComponent } from './pages/services/services.component';
import { AdvertsComponent } from './pages/adverts/adverts.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AdvertCreationComponent } from './pages/adverts/advert-creation/advert-creation.component';
import { AdvertDetailsComponent } from './pages/adverts/advert-details/advert-details.component';
import { ServiceDetailsComponent } from './pages/services/service-details/service-details.component';
import { RegisterComponent } from './pages/user/register/register.component';
import { LoginComponent } from './pages/user/login/login.component';
import { FaqComponent } from './pages/faq/faq.component';
import { UserProfileComponent } from './pages/user/user-profile/user-profile.component';
import { UserMyAdvertsComponent } from './pages/user/user-my-adverts/user-my-adverts.component';
import { UserMyFavoritesComponent } from './pages/user/user-my-favorites/user-my-favorites.component';
import { UserPaymentHistoryComponent } from './pages/user/user-payment-history/user-payment-history.component';

const routes: Routes = [

    { path: '', component: HomeComponent },

    { path: 'about-us', component: AboutUsComponent },

    { path: 'adverts', component: AdvertsComponent },
    { path: 'adverts/create', component: AdvertCreationComponent },
    { path: 'adverts/:slug', component: AdvertDetailsComponent },

    { path: 'contact-us', component: ContactUsComponent },

    { path: 'news', component: NewsComponent },
    { path: 'news/:slug', component: NewsPostComponent },

    { path: 'privacy-policy', component: PrivacyPolicyComponent },

    { path: 'faq', component: FaqComponent },

    { path: 'services', component: ServicesComponent },
    { path: 'services/:slug', component: ServiceDetailsComponent },

    { path: 'register', component: RegisterComponent },
    { path: 'login', component: LoginComponent },

    { path: 'user/profile', component: UserProfileComponent },
    { path: 'user/my-adverts', component: UserMyAdvertsComponent },
    { path: 'user/my-favorites', component: UserMyFavoritesComponent },
    { path: 'user/payment-history', component: UserPaymentHistoryComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
