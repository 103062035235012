import { Component, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

    navbarShrinked = false;

    constructor() {}

    ngOnInit(): void {}

    @HostListener('document:scroll')
    scrollFunction(): void {
        if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
            this.navbarShrinked = true;
        } else {
            this.navbarShrinked = false;
        }
    }

}
