<div class="authentication-form inner-content-wrapper">
    <div class="login-or-register">
        <div [ngClass]="{'login toggler': true, 'active': formType === 'login'}" (click)="changeFormType('login')">
            <div class="icon">
                <span class="relt-icon-key"></span>
            </div>
            <p>Prisijungti</p>
        </div>
        <div [ngClass]="{'register toggler': true, 'active': formType === 'register'}" (click)="changeFormType('register')">
            <div class="icon">
                <span class="relt-icon-agent"></span>
            </div>
            <p>Registruotis</p>
        </div>
    </div>
    <div class="external-ways">
        <p>Prisijungti galite:</p>
        <div class="options">
            <a routerLink="#" class="facebook"><i class="fab fa-facebook-f"></i> Prisijungti su Facebook</a>
            <a routerLink="#" class="g-plus"><i class="fab fa-google-plus-g"></i></a>
            <a routerLink="#" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
        </div>
    </div>
    <div class="divider">
        <p><span>arba</span></p>
    </div>
    <div class="main-form">
        <p class="action-title">{{ formType === 'login' ? 'Prisijungimas' : 'Registracija' }}</p>
        <div class="inputs">
            <div class="input-group">
                <label for="emailInput">El. pašto adresas:</label>
                <input type="email" id="emailInput" placeholder="Jūsų el. pašto adresas">
            </div>
            <div class="input-group">
                <label for="passwordInput">Slaptažodis:</label>
                <input type="password" id="passwordInput" placeholder="Slaptažodis">
            </div>
        </div>
        <div class="additional-options">
            <div class="remember-me">
                <input type="checkbox"> Įsiminti mane
            </div>
            <div class="forgot-password">
                <p><a routerLink="#">Pamiršau slaptažodį</a></p>
            </div>
        </div>
        <div class="button-wrapper">
            <a routerLink="/user/profile" class="button">{{ formType === 'login' ? 'Prisijungti' : 'Registruotis' }}</a>
        </div>
    </div>
</div>
