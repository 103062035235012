<div class="object-type form-elements-group">
    <label>Pasirinkite objekto tipą:</label>
    <!-- TODO: move this radio select to seperate component -->
    <ul class="type-selection">
        <li
            *ngFor="let obType of objectTypes"
            [ngClass]="{'active': obType.active}"
            (click)="onObjectTypeChange(obType.name)"
        >
            <span class="icon {{ obType.icon }}"></span>
            {{ obType.name }}
        </li>
    </ul>
</div>
<div class="action-type form-elements-group">
    <label>Pasirinkite veiksmą:</label>
    <ul class="type-selection">
        <li
            *ngFor="let acType of actionTypes"
            [ngClass]="{'active': acType.active}"
            (click)="onActionTypeChange(acType.name)"
        >
            <span class="icon {{ acType.icon }}"></span>
            {{ acType.name }}
        </li>
    </ul>
</div>
<div class="address-and-price form-elements-group">
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label>Vietovė:</label>
                <input type="text" class="form-control">
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label>Kaina:</label>
                <input type="number" class="form-control">
            </div>
        </div>
    </div>
</div>
