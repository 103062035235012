<div class="user-profile-page content-wrapper background-with-overlays" style="--background: url('/assets/img/shared/pages-with-photo-bg/background.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-user-profile-navigation-block></app-user-profile-navigation-block>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="inner-content-wrapper">
                    <h2>Mokėjimų istorija</h2>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="payment-list-wrapper">
                                <div class="payment-block">
                                    <div class="row">
                                        <div class="col">
                                            <p class="block-title">Šiandien</p>
                                        </div>
                                    </div>
                                    <div class="row payment-row">
                                        <div class="col-lg-8">
                                            <p class="title">skelbimo id</p>
                                            <p class="date">2020-11-20</p>
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="amount">-3,52 €</p>
                                        </div>
                                    </div>
                                    <div class="row payment-row">
                                        <div class="col-lg-8">
                                            <p class="title">skelbimo id</p>
                                            <p class="date">2020-11-20</p>
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="amount">-3,52 €</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="payment-block">
                                    <div class="row">
                                        <div class="col">
                                            <p class="block-title">Vakar</p>
                                        </div>
                                    </div>
                                    <div class="row payment-row">
                                        <div class="col-lg-8">
                                            <p class="title">skelbimo id</p>
                                            <p class="date">2020-11-20</p>
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="amount">-3,52 €</p>
                                        </div>
                                    </div>
                                    <div class="row payment-row">
                                        <div class="col-lg-8">
                                            <p class="title">skelbimo id</p>
                                            <p class="date">2020-11-20</p>
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="amount">-3,52 €</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
