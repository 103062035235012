import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-newest-and-services',
    templateUrl: './home-newest-and-services.component.html',
    styleUrls: ['./home-newest-and-services.component.scss']
})
export class HomeNewestAndServicesComponent implements OnInit {

    newestEstates = [1, 2, 3, 4, 5];

    constructor() { }

    ngOnInit(): void {
    }

}
