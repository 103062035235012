<header class="information-page-header" style="--background: url('/assets/img/pages/privacy-policy/privacy-policy-header.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Privatumo politika</h1>
            </div>
        </div>
    </div>
</header>

<div class="info-page content-wrapper buildings-bottom">
    <div class="container">
        <div class="row">
            <div class="col-12 body-wrapper">
                <h2 class="block-title">Privatumo politika</h2>
                <p class="highlighted">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odit voluptate eligendi mollitia soluta quos quisquam nemo nesciunt omnis nulla fuga.</p>
                <h3>Lorem ipsum dolor sit.</h3>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus quibusdam beatae similique unde expedita dolorum deserunt sunt ex. Debitis hic adipisci sunt autem, unde delectus enim repellat nemo facere eligendi sequi nobis ipsa accusamus vero nostrum, iure, quidem pariatur quia?</p>
                <ul>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                    <li>Eaque repellendus aliquid atque minus enim omnis maxime!</li>
                    <li>Esse cupiditate quasi ad qui error veritatis eius?</li>
                    <li>Vel nisi consequatur ducimus adipisci porro, repudiandae nesciunt.</li>
                </ul>
                <h3>Lorem ipsum dolor, sit amet consectetur adipisicing.</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam totam eos numquam dignissimos cupiditate, rem velit ipsa eius aut sed quasi illum saepe omnis quos voluptatum voluptas natus qui quaerat facere cumque nulla. Amet voluptate et dolorum eum architecto autem!</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid corporis odio explicabo fugit ipsum sed molestiae vitae enim ullam? Omnis possimus suscipit, autem quisquam impedit reprehenderit fuga ex accusamus vel itaque assumenda vero enim quia et? Quia dolore perspiciatis amet sequi iste aliquid quos minus id sunt quisquam molestias, quis exercitationem rem harum voluptates ratione cumque, nam quas, a expedita.</p>
                <h3>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit quia, perspiciatis placeat nihil eligendi quod delectus quisquam doloribus eveniet maxime similique amet impedit reprehenderit quos consequuntur sapiente? Libero, assumenda voluptate.</p>
                <ul>
                    <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
                    <li>Rem possimus asperiores molestias officia sapiente, reiciendis totam?</li>
                    <li>Molestiae quam nemo consequatur numquam suscipit, in obcaecati.</li>
                    <li>Cum facilis dolores impedit aperiam! Officia, iste ipsum.</li>
                    <li>Eum cum voluptatum eos molestiae, aut non tempora!</li>
                </ul>
                <h3>Lorem ipsum dolor sit amet consectetur.</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore nostrum, fuga itaque necessitatibus ducimus accusamus in deserunt quos eos libero? Et esse doloremque deserunt quaerat sint. Officiis illum neque nostrum!</p>
                <p>Omnis dolor, consequuntur, eveniet minima, incidunt quo excepturi blanditiis labore aut distinctio rerum ad saepe dolores maxime sunt consectetur culpa beatae esse placeat necessitatibus! Eaque mollitia non quia. Voluptatibus, tempore.</p>
                <ul>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus!</li>
                    <li>Similique sequi et harum dolorum optio amet laboriosam cum?</li>
                    <li>Optio voluptatum tenetur facilis asperiores iure suscipit ullam. Excepturi!</li>
                    <li>Quis natus delectus quaerat reprehenderit. A corporis minima consequuntur!</li>
                    <li>Labore neque esse commodi magni assumenda ducimus recusandae autem.</li>
                    <li>Maiores officiis modi dolorem odio, officia neque vero dolore!</li>
                    <li>Maiores, aliquid facilis velit quae quia eveniet ipsa quas?</li>
                    <li>Vero veritatis enim, molestiae in illum exercitationem totam quo.</li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam exercitationem aliquid, molestias inventore fugiat ad, asperiores aperiam eos commodi ratione repellat, aliquam reprehenderit. Quasi voluptas consectetur deleniti, voluptate autem ipsa illo asperiores id fugit ut, velit quod tempore tempora ad culpa? Maxime provident at accusantium maiores neque? Quidem, veniam commodi.</p>
                <p>Quibusdam sapiente molestias iure rem nemo porro veritatis, aliquid magni id vitae voluptate, voluptas optio laborum ad sit iste eaque corrupti a laudantium ea doloremque doloribus. Rem, sint officia iure omnis illo quasi quos expedita distinctio recusandae corrupti modi consectetur eos quas dolorum nemo nisi doloribus nulla facilis fuga tempora.</p>
                <p>Quaerat culpa itaque ex necessitatibus a distinctio, aut quos ipsam eos libero eaque voluptate reiciendis tempore, nemo cumque corporis quae provident ipsa. Illo, cumque veniam. Nostrum quis perferendis sit ipsam asperiores, explicabo impedit cumque fugiat labore optio laborum praesentium debitis. Dicta alias fugiat et enim aut vero sequi commodi perspiciatis.</p>
            </div>
        </div>
    </div>
</div>
