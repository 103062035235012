<div class="page-with-photo-bg content-wrapper background-with-overlays" style="--background: url('/assets/img/shared/pages-with-photo-bg/background.jpg')">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                <div class="creation-form inner-content-wrapper less-padding">
                    <div class="title">
                        <h1>Skelbimo kūrimas: <span>{{ stepTitle() }}</span></h1>
                    </div>
                    <div class="creation-navigation">
                        <ul [ngClass]="{'step-2': activeStep === 2, 'step-3': activeStep === 3, 'step-4': activeStep === 4, 'step-5': activeStep === 5}">
                            <li
                                *ngFor="let step of steps; let i = index"
                                [ngClass]="{'active': activeStep >= i + 1}"
                                (click)="onChangeStep(i + 1)"
                            >
                                <span>{{ step }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="step-body advert-creation-form-wrapper">
                        <app-advert-creation-step-object-type *ngIf="activeStep === 1"></app-advert-creation-step-object-type>
                        <app-advert-creation-step-object-information *ngIf="activeStep === 2"></app-advert-creation-step-object-information>
                        <app-advert-creation-step-additional-information *ngIf="activeStep === 3"></app-advert-creation-step-additional-information>
                        <app-advert-creation-step-payment *ngIf="activeStep === 4"></app-advert-creation-step-payment>
                        <app-advert-creation-step-ending *ngIf="activeStep === 5"></app-advert-creation-step-ending>
                    </div>
                    <div class="bottom-navigation-buttons" *ngIf="activeStep < 5">
                        <button routerLink="#" class="btn btn-secondary" (click)="onPreviousStepClick()">
                            {{ prevButtonText() }}
                        </button>
                        <button routerLink="#" class="btn btn-primary" (click)="onNextStepClick()">
                            {{ nextButtonText() }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
