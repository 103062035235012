<div class="info-page content-wrapper">
    <div class="contact-info-wrapper background-with-overlays" style="--background: url('/assets/img/pages/contact-us/contact-us-header.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-9">
                    <div class="contact-info-box">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="main-info">
                                    <div class="contact-methods">
                                        <h1>Kontaktai</h1>
                                        <div class="method">
                                            <div class="row">
                                                <div class="col-2 col-sm-2 col-md-1 col-lg-2 icon"><i class="fas fa-paper-plane"></i></div>
                                                <div class="col-10 col-sm-10 col-md-11 col-lg-10">
                                                    <p class="title">El. pašto adresas:</p>
                                                    <p class="description">info@relt.lt</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="method">
                                            <div class="row">
                                                <div class="col-2 col-sm-2 col-md-1 col-lg-2 icon"><i class="fas fa-phone-alt"></i></div>
                                                <div class="col-10 col-sm-10 col-md-11 col-lg-10">
                                                    <p class="title">Tel. nr.:</p>
                                                    <p class="description">+370 6000 0000</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="requisites">
                                        <h2>Rekvizitai</h2>
                                        <div class="requisite-box">
                                            <p class="title">Adresas:</p>
                                            <p class="description">Draugystės g. 17, Kaunas</p>
                                        </div>
                                        <div class="requisite-box">
                                            <p class="title">Įmonės kodas:</p>
                                            <p class="description">3000000000000</p>
                                        </div>
                                        <div class="requisite-box">
                                            <p class="title">Reg. adresas:</p>
                                            <p class="description">Draugystės g. 17, Kaunas</p>
                                        </div>
                                        <div class="requisite-box">
                                            <p class="title">Bankas:</p>
                                            <p class="description">Luminor A/s LT27 0000 0000 0000 0000</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="contact-form">
                                    <h2>Turite klausimų? Susisiekite su mumis!</h2>
                                    <p class="description">Užpildykite užklausos formą ir mes susisieksime su Jumis artimiausiu metu.</p>
                                    <form>
                                        <div class="row">
                                            <div class="col">
                                                <label for="">Jūsų vardas:</label>
                                                <input type="text" class="form-control" placeholder="Antanas">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-6">
                                                <label for="">Jūsų el. pašto adresas:</label>
                                                <input type="text" class="form-control" placeholder="antanas@pastas.lt">
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <label for="">Jūsų tel. nr.:</label>
                                                <input type="text" class="form-control" placeholder="+370 123 45678">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <label for="">Jūsų žinutė mums:</label>
                                                <textarea id="" class="form-control" placeholder="Norėčiau sužinoti apie..."></textarea>
                                            </div>
                                        </div>
                                        <div class="row button-row">
                                            <div class="col">
                                                <input type="submit" class=" btn btn-primary submit-btn" value="Siųsti žinutę">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="map-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2293.76048902282!2d23.99870561604894!3d54.90712596436031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e7183fd4edef7d%3A0x267be6138e4d4ab5!2sDraugyst%C4%97s%20g.%2017%2C%20Kaunas%2051229!5e0!3m2!1sen!2slt!4v1606118672894!5m2!1sen!2slt" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
