<div class="row article">
    <div class="col-md-12 col-lg-4 col-xl-4">
        <div class="image">
            <a routerLink="/news/news-slug">
                <img src="https://via.placeholder.com/480x350" alt="news image" class="img-fluid">
            </a>
            <div class="category">
                <p><i class="fas fa-dolly-flatbed"></i> Kategorija</p>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-lg-8 col-xl-6 article-info">
        <div class="title">
            <h3>
                <a routerLink="/news/news-slug">Mes esame entuziastingų profesionalų komanda</a>
            </h3>
        </div>
        <div class="excerpt">
            <p>Repudiandae adipisci harum consequatur omnis magnam inventore quae. Nulla ipsum pariatur nemo omnis asperiores, dolorum excepturi nostrum rerum placeat recusandae ad reiciendis cupiditate minima laudantium commodi esse voluptate quaerat, labore ut minus.</p>
        </div>
        <div class="bottom">
            <p class="publish-date">Paskelbimo data: 2020/09/08</p>
            <p class="read-more">
                <a routerLink="/news/news-slug">skaityti daugiau →</a>
            </p>
        </div>
    </div>
</div>
