<header class="information-page-header" style="--background: url('/assets/img/pages/about-us/about-us-header.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Apie mus</h1>
            </div>
        </div>
    </div>
</header>

<div class="info-page content-wrapper">
    <div class="container">
        <div class="row about-us-info">
            <div class="col-xl-6 col-lg-7 col-md-12 info-col">
                <p class="pre-title">Apie mus</p>
                <h2>Mes esame entuziastingų profesionalų komanda</h2>
                <div class="description">
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                        dolores et ea rebum. Stet clita.</p>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                        dolores et ea rebum. Stet clita.</p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-5 col-md-12 img-col">
                <img src="/assets/img/pages/about-us/about-us-main-photo.jpg" alt="about us image">
            </div>
        </div>
        <div class="row values">
            <div class="col-12">
                <h2 class="values-title block-title">Mūsų deklaruojamos vertybės</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 value">
                <div class="row">
                    <div class="col-2 icon-col">
                        <span class="relt-icon-house-4"></span>
                    </div>
                    <div class="col-10 info-col">
                        <h4>Mūsų vertybė</h4>
                        <p>Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą
                            vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.
                            Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą
                            vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 value">
                <div class="row">
                    <div class="col-2 icon-col">
                        <span class="relt-icon-agent2"></span>
                    </div>
                    <div class="col-10 info-col">
                        <h4>Mūsų vertybė</h4>
                        <p>Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą
                            vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.
                            Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą
                            vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.
                            Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 value">
                <div class="row">
                    <div class="col-2 icon-col">
                        <span class="relt-icon-insurance-1"></span>
                    </div>
                    <div class="col-10 info-col">
                        <h4>Mūsų vertybė</h4>
                        <p>Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą
                            vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.
                            Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą
                            vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.
                            Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą
                            vertybę ir dar šis bei tas.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
