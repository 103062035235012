<div class="home-newest-and-services">
    <div class="newest-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="block-title">Naujausi Mūsų skelbimai</h2>
                </div>
                <div class="col-lg-6">
                    <app-slider-navigation-buttons></app-slider-navigation-buttons>
                </div>
            </div>
            <div class="row slider">
                <div class="col-lg-4" *ngFor="estate of newestEstates">
                    <app-advert-box2></app-advert-box2>
                </div>
            </div>
            <div class="row">
                <div class="col view-more-col">
                    <a routerLink="/adverts" class="btn btn-secondary view-more-btn">Žiūrėti visus skelbimus</a>
                </div>
            </div>
        </div>
    </div>
    <div class="services-wrapper">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2 class="block-title">Mūsų paslaugos</h2>
                </div>
            </div>
            <div class="row services">
                <div class="col-lg-4 service">
                    <div class="icon">
                        <span class="relt-icon-house-2"></span>
                    </div>
                    <h3>NT vertinimas</h3>
                    <p>Aprašymas apie paslaugą. Viena eilutė. Teksto turėtų būti ne per daugiausiai, nes niekas nenori skaityti ilgų tekstų. Kokios keturios eilutes būtų pakankama.</p>
                </div>
                <div class="col-lg-4 service">
                    <div class="icon">
                        <span class="relt-icon-contract"></span>
                    </div>
                    <h3>Dokumentų tvarkymas</h3>
                    <p>Aprašymas apie paslaugą. Viena eilutė. Teksto turėtų būti ne per daugiausiai, nes niekas nenori skaityti ilgų tekstų. Kokios keturios eilutes būtų pakankama.</p>
                </div>
                <div class="col-lg-4 service">
                    <div class="icon">
                        <span class="relt-icon-chat"></span>
                    </div>
                    <h3>Konsultavimas perkant / parduodant</h3>
                    <p>Aprašymas apie paslaugą. Viena eilutė. Teksto turėtų būti ne per daugiausiai, nes niekas nenori skaityti ilgų tekstų. Kokios keturios eilutes būtų pakankama.</p>
                </div>
            </div>
        </div>
    </div>
</div>
