import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-authentication-form',
    templateUrl: './authentication-form.component.html',
    styleUrls: ['./authentication-form.component.scss'],
})
export class AuthenticationFormComponent implements OnInit {

    @Input() formType = 'login';

    constructor() {}

    ngOnInit(): void {}

    changeFormType(type: string): void {
        this.formType = type;
    }

}
