<div class="home-main-slider">
    <div class="slider">
        <div class="slides">
            <div class="slide">
                <div class="image">
                    <div class="overlay"></div>
                    <img src="assets/img/pages/home/main-slider/slides/slide1.jpg" alt="slider image 1">
                </div>
                <div class="info">
                    <div class="block half-container">
                        <h1>Svajonių būsto paieškos lengvai ir greitai</h1>
                        <p class="message">Didelę auditoriją turintis nekilnojamo turto skelbimų portalas</p>
                        <p><a routerLink="/register" class="btn btn-primary btn-lg call-to-action">Registruotis →</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-nav half-container right-container">
            <div>
                <p>
                    <span class="current">2</span>
                    <span class="seperator">/</span>
                    <span class="total">03</span>
                    <span class="arrow">→</span>
                </p>
            </div>
        </div>
    </div>
    <div class="search-box">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3 search-control-box">
                <label>Pasirinkite miestą:</label>
                <input type="text" class="form-control">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 search-control-box">
                <label>Pasirinkite kategoriją:</label>
                <select class="form-control">
                    <option>Namas</option>
                    <option>Butas</option>
                    <option>Garažas</option>
                    <option>Garažas</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 search-control-box">
                <label>Pasirinkite kambarių skaičių:</label>
                <input type="text" class="form-control">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 search-control-box">
                <label>Pasirinkite kainą:</label>
                <input type="text" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3 search-control-box">
                <label>Pasirinkite plotą:</label>
                <input type="text" class="form-control">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 search-control-box"></div>
            <div class="col-sm-12 col-md-6 col-lg-3 search-control-box">
                <a routerLink="/adverts" class="btn btn-primary">Išplėstinė paieška</a>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 search-control-box">
                <a routerLink="/adverts" class="btn btn-primary">Ieškoti</a>
            </div>
        </div>
    </div>
</div>
