<div class="description form-elements-group">
    <div class="form-group">
        <label>Aprašymas:</label>
        <textarea class="form-control"></textarea>
    </div>
</div>

<div class="photos form-elements-group">
    <div class="form-group">
        <label>Nuotraukos:</label>
        <p class="additional-info">Galite įkelti iki 10 nuotraukų. Vieno failo maksimalus dydis 10 MB. Galimi formatai JPG, GIF, PNG.</p>
        <textarea class="form-control"></textarea>
    </div>
</div>

<div class="contact-info form-elements-group">
    <div class="row">
        <div class="col">
            <p class="label-text">Jūsų kontaktai (atvaizduojami skelbime)</p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label>Telefono numeris:</label>
                <input type="text" class="form-control">
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label>El. pašto adresas:</label>
                <input type="email" class="form-control">
            </div>
        </div>
    </div>
</div>

<div class="consent-with-website-rules form-elements-group">
    <div class="form-group form-check">
        <input type="checkbox" class="form-check-input">
        <label class="form-check-label">Sutinku su tinklalapio <a routerLink="#">taisyklėmis</a></label>
    </div>
</div>
