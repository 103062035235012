<div class="main-attributes form-elements-group">
    <div class="row">
        <div class="col">
            <h4>Pagrindinės ypatybės</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label>Kambarių skaičius:</label>
                <input type="number" class="form-control">
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label>Buto plotas:</label>
                <select class="form-control">
                    <option>35 m2</option>
                    <option>40 m2</option>
                    <option>45 m2</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label>Aukštas:</label>
                <select class="form-control">
                    <option>1</option>
                    <option>2</option>
                </select>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label>Name aukštų:</label>
                <select class="form-control">
                    <option>1</option>
                    <option>2</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label>Statybos metai:</label>
                <select class="form-control">
                    <option>1985</option>
                    <option>2000</option>
                </select>
            </div>
            <div class="toggle-field-checkbox form-group form-check">
                <input type="checkbox" class="form-check-input">
                <label class="form-check-label">Atlikta rekonstrukcija</label>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="toggle-field form-group">
                <label>Rekonstrukcijos metai</label>
                <select class="form-control">
                    <option>1985</option>
                    <option>2000</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label>Šildymas:</label>
                <select class="form-control">
                    <option>autonominis</option>
                    <option>autonominis</option>
                </select>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label>Būklė</label>
                <select class="form-control">
                    <option>įrengtas</option>
                    <option>neįrengtas</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label>Namo tipas:</label>
                <select class="form-control">
                    <option>kazkoks</option>
                    <option>kazkoks</option>
                </select>
            </div>
        </div>
        <div class="col-lg-6">
            <button class="add-more-attributes-button">+ pridėti papildomas ypatybes</button>
        </div>
    </div>
</div>
