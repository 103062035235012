import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-post-block-small',
  templateUrl: './news-post-block-small.component.html',
  styleUrls: ['./news-post-block-small.component.scss']
})
export class NewsPostBlockSmallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
