import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-advert-creation-step-object-type',
    templateUrl: './advert-creation-step-object-type.component.html',
    styleUrls: ['./advert-creation-step-object-type.component.scss']
})
export class AdvertCreationStepObjectTypeComponent implements OnInit {

    objectTypes = [
        { name: 'Butas', icon: 'relt-icon-buildings', active: true },
        { name: 'Namas', icon: 'relt-icon-house', active: false },
        { name: 'Sklypas', icon: 'relt-icon-park', active: false },
        { name: 'Patalpos', icon: 'relt-icon-blueprint1', active: false },
        { name: 'Garažas', icon: 'relt-icon-car', active: false },
    ];

    actionTypes = [
        { name: 'Parduoti', icon: 'relt-icon-sale', active: true },
        { name: 'Nuomoti', icon: 'relt-icon-rent', active: false },
    ];

    constructor() { }

    ngOnInit(): void {
    }

    onObjectTypeChange(name: string): void {
        this.objectTypes.map(x => x.name === name ? x.active = true : x.active = false);
    }

    onActionTypeChange(name: string): void {
        this.actionTypes.map(x => x.name === name ? x.active = true : x.active = false);
    }

}
