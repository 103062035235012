<footer>
    <div class="container">
        <div class="row top-row">
            <div class="col-md-6 col-lg-6 col-xl-3 offset-xl-1">
                <h4>Nuorodos</h4>
                <ul class="footer-links">
                    <li><a routerLink="/about-us">Apie mus</a></li>
                    <li><a routerLink="/privacy-policy">Privatumo politika</a></li>
                    <li><a routerLink="/services">Paslaugos</a></li>
                    <li><a routerLink="/faq">D.U.K.</a></li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
                <h4>Kontaktai</h4>
                <ul class="contacts footer-links">
                    <li>
                        <p class="icon"><i class="fas fa-mobile-alt"></i></p>
                        <p>Tel. nr.: <a href="tel:+3706000000">+370 6000 000</a></p>
                    </li>
                    <li>
                        <p class="icon"><i class="fas fa-envelope"></i></p>
                        <p>El. paštas: <a href="mailto:pastas@pastas.lt">pastas@pastas.lt</a></p>
                    </li>
                    <li>
                        <p class="icon"><i class="fas fa-map-marker-alt"></i></p>
                        <p>Adresas: <a href="https://maps.google.com">Gatvės g. 37, Kaunas, LT-0000</a></p>
                    </li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-2">
                <h4>Apie mus</h4>
                <p class="about-us-text">Trumpa informacija apie mūsų įmonė. Kuo mes užsiimame ir kuo kvėpuojame.</p>
                <ul class="social-icons">
                    <li><a routerLink="#"><i class="fab fa-facebook-square"></i></a></li>
                    <li><a routerLink="#"><i class="fab fa-facebook-square"></i></a></li>
                    <li><a routerLink="#"><i class="fab fa-facebook-square"></i></a></li>
                    <li><a routerLink="#"><i class="fab fa-facebook-square"></i></a></li>
                </ul>
            </div>
        </div>
        <div class="row bottom-row">
            <div class="col-lg-6 col-md-12 offset-lg-1">
                <p>Visos teisės saugomos (c) 2020 RELT.LT.</p>
            </div>
            <div class="col-lg-5 col-md-12">
                <p>Sukurta <a href="https://itma.lt" target="_blank">ITMA.LT</a></p>
            </div>
        </div>
    </div>
</footer>
