<div class="user-profile-page content-wrapper background-with-overlays" style="--background: url('/assets/img/shared/pages-with-photo-bg/background.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-user-profile-navigation-block></app-user-profile-navigation-block>
            </div>
        </div>
        <div class="row page-content">
            <div class="col">
                <div class="inner-content-wrapper">
                    <div class="row">
                        <div class="col">
                            <h2>Mano skelbimai</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <div class="options">
                                ...
                            </div>
                            <div class="list">
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
