<nav class="navbar navbar-expand-lg navbar-light sticky-top" [class.shrinked]="navbarShrinked">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/logo.png" alt="logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto main-links">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Pradžia <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/adverts" routerLinkActive="active">Skelbimai</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/about-us" routerLinkActive="active">Apie mus</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/services" routerLinkActive="active">Paslaugos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/news" routerLinkActive="active">Naujienos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/contact-us" routerLinkActive="active">Kontaktai</a>
                </li>
            </ul>
            <ul class="navbar-nav additional-links">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/login" routerLinkActive="active">
                        Prisijungti
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.89" height="14.89" viewBox="0 0 14.89 14.89">
                            <g id="Icon_feather-arrow-right-circle" data-name="Icon feather-arrow-right-circle" transform="translate(0.5 0.5)">
                                <path id="Path_4" data-name="Path 4" d="M16.89,9.945A6.945,6.945,0,1,1,9.945,3,6.945,6.945,0,0,1,16.89,9.945Z" transform="translate(-3 -3)" fill="none" stroke="#cb9865" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                <path id="Path_5" data-name="Path 5" d="M18,17.555l2.777-2.777L18,12" transform="translate(-11.055 -7.832)" fill="none" stroke="#cb9865" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                <path id="Path_6" data-name="Path 6" d="M12,18h5.555" transform="translate(-7.832 -11.055)" fill="none" stroke="#cb9865" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            </g>
                        </svg>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/adverts/create" routerLinkActive="active">
                        Įdėti skelbimą
                        <svg id="Icon_ionic-ios-add-circle-outline" data-name="Icon ionic-ios-add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="14.11" height="14.11" viewBox="0 0 14.11 14.11">
                            <path id="Path_2" data-name="Path 2" d="M16,13.034H13.926V10.958a.446.446,0,0,0-.892,0v2.077H10.958a.427.427,0,0,0-.446.446.432.432,0,0,0,.446.446h2.077V16a.432.432,0,0,0,.446.446A.444.444,0,0,0,13.926,16V13.926H16a.446.446,0,1,0,0-.892Z" transform="translate(-6.425 -6.425)" fill="#cb9865"/>
                            <path id="Path_3" data-name="Path 3" d="M10.43,4.325A6.1,6.1,0,1,1,6.112,6.112,6.065,6.065,0,0,1,10.43,4.325m0-.95a7.055,7.055,0,1,0,7.055,7.055A7.054,7.054,0,0,0,10.43,3.375Z" transform="translate(-3.375 -3.375)" fill="#cb9865"/>
                        </svg>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">EN</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
