<div class="user-profile-page content-wrapper background-with-overlays" style="--background: url('/assets/img/shared/pages-with-photo-bg/background.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-user-profile-navigation-block></app-user-profile-navigation-block>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="inner-content-wrapper">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="categories">
                                <h4>Kategorijos:</h4>
                                <h5>NT Lietuvoje:</h5>
                                <ul class="categories-list">
                                    <li>
                                        <span class="icon relt-icon-blueprint1"></span>
                                        <span class="title">Namai:</span>
                                        <a routerLink="#">4 skelbimai</a>
                                    </li>
                                    <li>
                                        <span class="icon relt-icon-house"></span>
                                        <span class="title">Butai:</span>
                                        <a routerLink="#">2 skelbimai</a>
                                    </li>
                                    <li>
                                        <span class="icon relt-icon-rent"></span>
                                        <span class="title">Sklypai:</span>
                                        <a routerLink="#">3 skelbimai</a>
                                    </li>
                                    <li>
                                        <span class="icon relt-icon-car"></span>
                                        <span class="title">Garažai:</span>
                                        <a routerLink="#">1 skelbimai</a>
                                    </li>
                                </ul>
                                <h5>NT užsienyje:</h5>
                                <ul class="categories-list">
                                    <li>
                                        <span class="icon relt-icon-blueprint1"></span>
                                        <span class="title">Namai:</span>
                                        <a routerLink="#">4 skelbimai</a>
                                    </li>
                                    <li>
                                        <span class="icon relt-icon-house"></span>
                                        <span class="title">Butai:</span>
                                        <a routerLink="#">2 skelbimai</a>
                                    </li>
                                    <li>
                                        <span class="icon relt-icon-rent"></span>
                                        <span class="title">Sklypai:</span>
                                        <a routerLink="#">3 skelbimai</a>
                                    </li>
                                    <li>
                                        <span class="icon relt-icon-car"></span>
                                        <span class="title">Garažai:</span>
                                        <a routerLink="#">1 skelbimai</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-8 offset-lg-1 list-wrapper">
                            <h2>Įsiminti skelbimai <span>(rasta: 158)</span></h2>
                            <div class="list">
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                                <app-advert-box1></app-advert-box1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
