<div class="home-most-popular">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 main-information-col">
                <div class="top-info">
                    <div class="information-content">
                        <h2 class="block-title">Populiariausi skelbimai</h2>
                        <p>Lorem ipsum dolor sit amet consectetur consequatur saepe mollitia dolorem aliquid cumque tempore expedita.</p>
                    </div>
                    <div class="slider-navigation">
                        <div class="text">
                            <p>Paspauskite rodyklę, kad matytumėte daugiau →</p>
                        </div>
                        <app-slider-navigation-buttons></app-slider-navigation-buttons>
                    </div>
                </div>
                <div class="bottom-info">
                    <p>Sutartiniai ženklai:</p>
                    <ul>
                        <li><span class="relt-icon-blueprint1"></span> Būsto kvadratūra</li>
                        <li><span class="relt-icon-elevator1"></span> Aukštas</li>
                        <li><span class="relt-icon-blueprint2"></span> Kambarių skaičius</li>
                        <li><span class="relt-icon-rent"></span> Veiksmas</li>
                        <li><span class="relt-icon-house"></span> Turto tipas</li>
                        <li><span class="relt-icon-park"></span> Plotas</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-9 slider-col">
                <div class="slider row">
                    <div class="slide col-lg-5" *ngFor="let estate of estates">
                        <div class="photo-wrapper">
                            <div class="photo">
                                <a routerLink="/adverts/some-estate">
                                    <img src="https://via.placeholder.com/500/500" class="img-fluid" alt="placeholder image">
                                </a>
                            </div>
                            <div class="overlay">
                                <div class="type">
                                    <p><span class="relt-icon-house"></span> Butas</p>
                                </div>
                                <div class="favorite">
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Heart</title><path d='M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='20'/></svg>
                                </div>
                                <div class="overlay-bottom">
                                    <p class="address"><i class="fas fa-map-marker-alt"></i> Kauno r., Žiegždrionys</p>
                                    <p class="price">130 000 €</p>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-info">
                            <h3>Pavadinimas susideda iš penkių ar daugiau žodžių!</h3>
                            <ul>
                                <li><span class="relt-icon-house"></span> butas</li>
                                <li><span class="relt-icon-blueprint2"></span> 5 kambariai</li>
                                <li><span class="relt-icon-blueprint1"></span> 56 m<sup>2</sup></li>
                                <li><span class="relt-icon-elevator1"></span> 5 aukštas</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
