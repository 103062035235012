import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-most-popular',
    templateUrl: './home-most-popular.component.html',
    styleUrls: ['./home-most-popular.component.scss']
})
export class HomeMostPopularComponent implements OnInit {

    estates = [1, 2, 3, 4];

    constructor() { }

    ngOnInit(): void {
    }

}
