<div class="home-partners">
    <div class="container">
        <div class="row">
            <div class="col">
                <h2 class="block-title inverted-color">Mūsų partneriai</h2>
            </div>
        </div>
        <div class="row partners">
            <div class="col-lg-3" *ngFor="partner of partners">
                <a routerLink="#">
                    <img src="/assets/img/pages/home/partners/logos/partner-logo-example.png" class="img-fluid" alt="partner logo example">
                </a>
            </div>
        </div>
    </div>
</div>
