<div class="info-page content-wrapper buildings-bottom">
    <div class="container">
        <div class="row main-info-wrapper">
            <div class="col-md-12 col-lg-6">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="#">Butai</a></li>
                        <li class="breadcrumb-item"><a routerLink="#">Kaunas</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Šilainiai</li>
                    </ol>
                </nav>
                <div class="photo-gallery">
                    <div class="main-photo">
                        <div class="photo">
                            <img src="https://via.placeholder.com/750/600" class="img-fluid" alt="image placeholder">
                        </div>
                        <div class="navigation">
                            <app-slider-navigation-buttons></app-slider-navigation-buttons>
                        </div>
                    </div>
                    <div class="row other-photos">
                        <div class="col">
                            <img src="https://via.placeholder.com/250/200" class="img-fluid" alt="image placeholder">
                        </div>
                        <div class="col">
                            <img src="https://via.placeholder.com/250/200" class="img-fluid" alt="image placeholder">
                        </div>
                        <div class="col with-more-photos-overlay">
                            <img src="https://via.placeholder.com/250/200" class="img-fluid" alt="image placeholder">
                            <div class="overlay">
                                <p>10+</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-5 offset-lg-1">
                <div class="row report-or-favorite">
                    <div class="col">
                        <button>
                            <i class="far fa-flag"></i>
                            <span>Raportuoti skelbimą</span>
                        </button>
                    </div>
                    <div class="col">
                        <button>
                            <i class="far fa-heart"></i>
                            <span>Įsiminti skelbimą</span>
                        </button>
                    </div>
                </div>
                <div class="row estate-title-and-meta">
                    <div class="col-12">
                        <h1>Pavadinimas susideda iš penkių ar daugiau žodžių!</h1>
                    </div>
                    <div class="col-6 meta-box">
                        <i class="fas fa-map-marker-alt"></i>
                        <p>Kauno r., Žiegždrionys</p>
                    </div>
                    <div class="col-6 meta-box">
                        <i class="fas fa-tag"></i>
                        <p>130 000 000 eur.</p>
                    </div>
                </div>
                <div class="row listed-info-main">
                    <div class="col-12">
                        <h2>Pagrindinė informacija</h2>
                        <ul>
                            <li>
                                <span class="relt-icon-blueprint2"></span>
                                <span class="title">Kambarių skaičius:</span>
                                <span class="description">4 kambariai</span>
                            </li>
                            <li>
                                <span class="relt-icon-house"></span>
                                <span class="title">Statybos metai:</span>
                                <span class="description">2012 m.</span>
                            </li>
                            <li>
                                <span class="relt-icon-rent"></span>
                                <span class="title">Namo tipas:</span>
                                <span class="description">mūrinis</span>
                            </li>
                            <li>
                                <span class="relt-icon-elevator1"></span>
                                <span class="title">Šildymas:</span>
                                <span class="description">centrinis</span>
                            </li>
                            <li>
                                <span class="relt-icon-park"></span>
                                <span class="title">Namo tipas:</span>
                                <span class="description">mūrinis</span>
                            </li>
                            <li>
                                <span class="relt-icon-blueprint1"></span>
                                <span class="title">Šildymas:</span>
                                <span class="description">centrinis</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row listed-info-more-features">
                    <div class="col-12">
                        <h2>Ypatybės</h2>
                        <ul>
                            <li>
                                <span class="relt-icon-pet-friendly"></span>
                                <span class="text">draugiškas augintiniams</span>
                            </li>
                            <li>
                                <span class="relt-icon-painting"></span>
                                <span class="text">naujai įrengtas</span>
                            </li>
                            <li>
                                <span class="relt-icon-car"></span>
                                <span class="text">vieta automobiliui</span>
                            </li>
                            <li>
                                <span class="relt-icon-bathroom"></span>
                                <span class="text">vonios kambarys</span>
                            </li>
                            <li>
                                <span class="relt-icon-pet-friendly"></span>
                                <span class="text">voljeras</span>
                            </li>
                            <li>
                                <span class="relt-icon-painting"></span>
                                <span class="text">naujos statybos</span>
                            </li>
                            <li>
                                <span class="relt-icon-car"></span>
                                <span class="text">garažas</span>
                            </li>
                            <li>
                                <span class="relt-icon-bathroom"></span>
                                <span class="text">sauna</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row additional-info-wrapper">
            <div class="col-md-12 col-lg-7 col-xl-6">
                <h2>Papildoma informacija</h2>
                <div class="info-body">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Pariatur perspiciatis tempora accusantium neque delectus beatae officiis sequi nam distinctio quasi repellat, blanditiis consectetur quod voluptatum quos ut laborum magni deleniti provident nobis libero dolores reiciendis eaque. Nam consequatur laborum totam quae voluptas ad mollitia, distinctio est! Dolorem asperiores non rem.</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste in dolor aut quae molestiae quo.</li>
                        <li>Nulla dolores ea, ad, vitae aspernatur fugiat iste culpa rerum reprehenderit ipsum officiis, voluptate est.</li>
                        <li>Tempora incidunt ut, eveniet quos eaque cupiditate sit autem voluptatibus accusamus, veritatis unde similique molestiae.</li>
                        <li>Maiores accusantium rerum unde eaque ea dignissimos quis aliquam aliquid assumenda, eligendi ipsa, ipsam minus.</li>
                        <li>Rerum exercitationem vero adipisci laborum, debitis dicta possimus, iste, doloremque earum eaque doloribus omnis officia?</li>
                    </ul>
                    <p>Repellat, praesentium illo suscipit distinctio, labore facilis doloremque quam nostrum, sint tempore facere nobis aut adipisci rem esse? Maxime dignissimos, culpa dolores dolor quae harum voluptatem cumque voluptatum dolorum repellat sequi perspiciatis esse recusandae tempore provident eos molestias voluptatibus! Corrupti aliquam commodi architecto laboriosam at minima nobis fugiat odit laudantium!</p>
                    <h3>Consectetur adipisicing elit</h3>
                    <p>Obcaecati at quia est quidem cumque nemo dolore saepe qui aspernatur totam. Deserunt, qui error. Adipisci, sequi delectus consequuntur ab eos nobis perferendis consequatur voluptas repudiandae non quisquam nihil, amet labore, maiores dolorem voluptatibus dolorum vero esse quidem illum quod quasi deserunt. Rerum sunt magni odit, delectus adipisci animi cupiditate.</p>
                    <p>Eveniet dolor officiis voluptatibus quod fugit sed, nihil quia tenetur consectetur eum, doloremque minima temporibus magni voluptates maxime, cupiditate esse architecto neque quae. Commodi eius, sint enim laborum maiores, at ratione mollitia rerum aperiam ab nam possimus rem! Ex, vel. Modi corporis officiis voluptatibus. Accusantium dolor omnis quas praesentium cum?</p>
                    <h3>Nostrum eaque quaerat id dicta</h3>
                    <p>Laboriosam magnam deserunt consequuntur et beatae perspiciatis sapiente nulla officia incidunt praesentium, ex ratione similique doloremque placeat cum delectus vitae ipsam cumque odio maiores! Laborum eius qui dolore consequuntur nisi alias est ratione, mollitia provident nihil doloremque dolor commodi nam voluptatem? Rem odio quo, debitis quibusdam dolorum temporibus officiis provident.</p>
                    <p>Consequatur, sapiente! Excepturi officia accusantium recusandae voluptate doloremque! Esse sunt unde enim, reiciendis dolorem pariatur quidem dicta. Laborum ipsa asperiores eligendi nemo quia, dolor necessitatibus voluptatibus quisquam alias distinctio magni modi deleniti eum quasi voluptatum ipsam fugit at cum dicta quod excepturi minima? Reiciendis, aperiam. Asperiores qui velit deserunt corporis.</p>
                    <p>Deleniti dignissimos veniam repellat alias eaque deserunt assumenda, itaque quos tempore eum, accusantium eligendi ex velit et dicta laudantium perferendis corrupti sunt illo facilis nisi debitis ducimus est! Facere neque, totam doloremque odit pariatur, mollitia perferendis earum cum porro possimus rem cumque quod error voluptates quia a. Ex, expedita culpa.</p>
                </div>
                <div class="row seller-contacts">
                    <div class="col-md-12 col-lg-10 offset-lg-2 col-xl-7 offset-xl-5">
                        <div class="row align-items-start">
                            <div class="col-lg-5 contact-info-title">
                                <span class="relt-icon-agent icon"></span>
                                <span class="text">Asmuo:</span>
                            </div>
                            <div class="col-lg-7 contact-info-text">
                                <p class="name">Romas Ramanauskas</p>
                                <p class="phone">Tel. nr.: <a href="tel:+3706000000">+370 6000 000</a></p>
                                <p class="write"><a routerLink="#">rašyti el. laišką →</a></p>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-lg-5 contact-info-title">
                                <span class="relt-icon-house4 icon"></span>
                                <span class="text">Kaina:</span>
                            </div>
                            <div class="col-lg-7 contact-info-text">
                                <p class="price">130 000 000 €</p>
                                <p class="price-about">(150 € / m<sup>2</sup>)</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 button">
                                <a routerLink="#" class="btn btn-primary">Žiūrėti pardavėjo skelbimus →</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-5 col-xl-4 offset-xl-2 similar-estates-side">
                <h2>Panašūs skelbimai</h2>
                <div class="estate-box">
                    <app-advert-box2></app-advert-box2>
                </div>
                <div class="estate-box">
                    <app-advert-box2></app-advert-box2>
                </div>
                <div class="estate-box">
                    <app-advert-box2></app-advert-box2>
                </div>
                <div class="estate-box">
                    <app-advert-box2></app-advert-box2>
                </div>
            </div>
        </div>
        <div class="row similar-estates-wrapper">
            <div class="col-lg-12">
                <div class="row title-row">
                    <div class="col-md-12 col-lg-6">
                        <h2>Panašūs skelbimai</h2>
                    </div>
                    <div class="col-lg-6 navigation-col">
                        <app-slider-navigation-buttons></app-slider-navigation-buttons>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 estate-box">
                <app-advert-box2></app-advert-box2>
            </div>
            <div class="col-md-12 col-lg-4 estate-box">
                <app-advert-box2></app-advert-box2>
            </div>
            <div class="col-md-12 col-lg-4 estate-box">
                <app-advert-box2></app-advert-box2>
            </div>
        </div>
    </div>
</div>
