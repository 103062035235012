import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-post',
  templateUrl: './news-post.component.html',
  styleUrls: ['./news-post.component.scss']
})
export class NewsPostComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
