<!-- Modal for deleting user comfirmation -->
<!-- <div
    class="modal fade"
    id="deleteUserModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true"
    [ngClass]="{'show': showDeleteUserModal}"
>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" (click)="showDeleteUserModal = false" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="showDeleteUserModal = false">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div> -->

<!-- Page content -->
<div class="user-profile-page content-wrapper background-with-overlays" style="--background: url('/assets/img/shared/pages-with-photo-bg/background.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-user-profile-navigation-block></app-user-profile-navigation-block>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="inner-content-wrapper">
                    <div class="row">
                        <div class="col-md-12 col-lg-6 col-xl-4">
                            <div class="box">
                                <h2>Mano profilis</h2>
                                <div class="form-group">
                                    <label>Jūsų vardas:</label>
                                    <input type="text" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label>Jūsų pavardė:</label>
                                    <input type="text" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label>Jūsų el. pašto adresas:</label>
                                    <input type="text" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label>Jūsų telefono numeris:</label>
                                    <input type="text" class="form-control">
                                </div>
                                <div class="btn btn-primary btn-update-info">Atnaujinti</div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6 col-xl-4">
                            <div class="box">
                                <h2>Slaptažodžio keitimas</h2>
                                <div class="form-group">
                                    <label>Dabartinis slaptažodis:</label>
                                    <input type="password" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label>Naujas slaptažodis:</label>
                                    <input type="password" class="form-control">
                                </div>
                                <div class="btn btn-primary btn-update-info">Atnaujinti</div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6 col-xl-4">
                            <div class="box">
                                <h2>Ištrinti savo paskyrą</h2>
                                <p>Ištrindami savo paskyrą visam, prarasite galimybę vėl prisijungti prie jos, taip pat, prarasite visus savo skelbimus ir kitą išsaugotą, su paskyra susijusią, informaciją.</p>
                                <div
                                    class="btn btn-secondary btn-update-info"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    (click)="showDeleteUserModal = true"
                                >
                                    Ištrinti paskyrą
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
