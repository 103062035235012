<header class="information-page-header" style="--background: url('/assets/img/pages/news/news-header.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Mes esame entuziastingų profesionalų komanda</h1>
            </div>
        </div>
    </div>
</header>

<div class="info-page content-wrapper buildings-bottom">
    <div class="container">
        <div class="row">
            <div class="col-12 news-post-body-wrapper">
                <div class="sub-title">
                    <p>Naujienos</p>
                </div>
                <div class="news-meta">
                    <p class="category">Kategorija</p>
                    <p class="date">2020/09/08</p>
                </div>
                <div class="post-body">
                    <h2>Lorem ipsum dolor sit amet.</h2>
                    <p class="excerpt">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Provident repudiandae doloremque, tenetur excepturi unde iste assumenda porro itaque officiis reiciendis dolorem iure, nihil exercitationem ex consequuntur sequi, impedit deserunt molestias? Suscipit nulla, neque fuga necessitatibus, illum enim corrupti quas a, provident delectus ex eos omnis nobis voluptatem animi rerum. Repudiandae?</p>
                    <p class="image"><img src="https://via.placeholder.com/1200/350" alt="image placeholder"></p>
                    <h3>Lorem ipsum dolor sit amet consectetur.</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima qui error quo nulla sint, inventore consequuntur similique quidem aspernatur! Aut eveniet quibusdam minus dolor ipsa voluptate, optio blanditiis animi odio voluptatum aliquam nesciunt ratione, assumenda incidunt consequatur eaque ex fugit consectetur vel? Ad ipsum sunt ab vero eos distinctio dicta.</p>
                    <p class="quote">"Lorem ipsum dolor sit amet consectetur adipisicing elit."</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio dolorum aliquid quam magni aspernatur dolores id repellendus, vero velit, repellat omnis nulla perferendis inventore, blanditiis sapiente commodi non culpa maiores accusantium quasi. Repudiandae eveniet numquam odit distinctio obcaecati facilis eius architecto dolore vitae aperiam. Asperiores, modi quae maxime repellat magni esse nisi quisquam, dolorem suscipit impedit libero voluptatem illum aperiam!</p>
                    <p>Hic odit voluptas, iusto libero expedita qui sequi quibusdam ab dicta, eius eos, officia aliquid ut eligendi error eaque! Reiciendis a facere possimus voluptate deleniti commodi tempora eaque sed doloribus dicta beatae quod ea, ab mollitia error laboriosam, doloremque perspiciatis repellat quis quisquam sint molestias nam! Accusantium a accusamus omnis aperiam neque libero aliquam, consequatur, nostrum suscipit, quas illum fugit?</p>
                    <p class="image"><img src="https://via.placeholder.com/1200/350" alt="image placeholder"></p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, exercitationem odit? Consequuntur sed est voluptatum, quisquam asperiores eos veniam error pariatur qui? A neque iste vel aliquam nobis voluptates reiciendis natus, quasi quidem excepturi molestiae tenetur dolore id consequatur eum minus, eligendi modi cum autem fugit voluptatibus repudiandae? Accusantium architecto expedita aliquid modi exercitationem tenetur ipsa ullam doloribus, nisi possimus!</p>
                    <h3>Lorem ipsum dolor sit amet.</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam enim nihil minima, sunt modi sapiente, velit odio natus quisquam ex ratione fugit non eaque recusandae neque et laudantium. Amet hic impedit asperiores laborum aut nesciunt error, totam ratione harum consequatur sed veritatis sit eum sequi vero id cumque optio labore vel cum maxime. Sapiente, praesentium?</p>
                    <p>Harum alias nam iste vero accusamus dicta possimus quisquam facilis deserunt aspernatur fugiat quasi eaque, quis ullam molestiae, voluptates est reprehenderit quaerat, nobis laborum. Nihil et earum, atque officiis maxime quod assumenda totam. Beatae libero quam corporis odit id nihil, delectus voluptatum ipsum, quisquam sed molestiae laborum perspiciatis rem eaque nisi repudiandae eius hic minus.</p>
                    <p>Error, quisquam dolorum qui unde eius consectetur totam, a minus quibusdam veniam magni nesciunt! Nobis delectus illum quos vel perspiciatis officiis deserunt nemo aliquid animi soluta consequatur, nesciunt atque minima dolorem aut magni vitae totam ratione? Odit, vitae at voluptatem, autem delectus est soluta earum consequatur repellat consectetur recusandae aperiam? Corporis ipsa modi reprehenderit doloribus.</p>
                </div>
            </div>
        </div>
        <div class="row more-news">
            <div class="col-12">
                <h2 class="block-title">Mūsų populiariausios naujienos</h2>
            </div>
            <div class="col-12 articles">
                <app-news-post-block-small></app-news-post-block-small>
                <app-news-post-block-small></app-news-post-block-small>
            </div>
        </div>
    </div>
</div>
