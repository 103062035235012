import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advert-creation-step-object-information',
  templateUrl: './advert-creation-step-object-information.component.html',
  styleUrls: ['./advert-creation-step-object-information.component.scss']
})
export class AdvertCreationStepObjectInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
