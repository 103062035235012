<div class="user-profile-navigation">
    <div class="row align-items-center">
        <div class="col-md-12 col-lg-4 welcome-col">
            <p><span class="relt-icon-deal"></span>Sveiki prisijungę, Antanas</p>
        </div>
        <div class="col-md-12 col-lg-8 profile-links-col">
            <ul>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <a routerLink="/user/profile">
                        <span class="relt-icon-agent1"></span>
                        Mano profilis
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <a routerLink="/user/my-adverts">
                        <span class="relt-icon-real-estate3"></span>
                        Mano skelbimai
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <a routerLink="/user/my-favorites">
                        <span class="relt-icon-house2"></span>
                        Įsiminti skelbimai
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <a routerLink="/user/payment-history">
                        <span class="relt-icon-house4"></span>
                        Mokėjimų istorija
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
