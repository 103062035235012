import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-advert-creation',
    templateUrl: './advert-creation.component.html',
    styleUrls: ['./advert-creation.component.scss'],
})
export class AdvertCreationComponent implements OnInit {

    activeStep = 1;
    madePayment = false;
    steps = ['Objekto tipas', 'Objekto informacija', 'Papildoma ir kontaktinė informacija', 'Skelbimo apmokėjimas', 'Pabaiga'];

    constructor() {}

    ngOnInit(): void {}

    stepTitle(): string {
        return this.steps[this.activeStep - 1];
    }

    onPreviousStepClick(): void {
        if (this.activeStep > 1) {
            this.activeStep--;
        }
        window.scroll(0, 0);
    }

    onNextStepClick(): void {
        if (this.activeStep === 4 && !this.madePayment) {
            this.madePayment = true;
        } else if (this.activeStep < 5 && this.madePayment) {
            this.activeStep++;
        } else {
            this.activeStep++;
        }
        window.scroll(0, 0);
    }

    onChangeStep(step: number): void {
        this.activeStep = step;
        window.scroll(0, 0);
    }

    prevButtonText(): string {
        if (this.activeStep === 1) {
            return 'Atšaukti';
        }
        return '← Grįžti';
    }

    nextButtonText(): string {
        if (this.activeStep === 4 && this.madePayment) {
            return 'Apmokėti ir įdėti skelbimą';
        }
        return 'Toliau →';
    }

}
