import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-main-slider',
    templateUrl: './home-main-slider.component.html',
    styleUrls: ['./home-main-slider.component.scss']
})
export class HomeMainSliderComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
