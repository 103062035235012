<header class="information-page-header" style="--background: url('/assets/img/pages/news/news-header.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Naujienos</h1>
            </div>
        </div>
    </div>
</header>

<div class="info-page content-wrapper buildings-bottom">
    <div class="container">
        <div class="row pinned-article">
            <div class="col-md-12 col-lg-6">
                <div class="image">
                    <a routerLink="/news/news-slug">
                        <img src="https://via.placeholder.com/750x600" alt="news image" class="img-fluid">
                    </a>
                    <div class="category">
                        <p><i class="fas fa-dolly-flatbed"></i> Kategorija</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 article-info">
                <div class="sub-info">
                    <p>Kategorija</p>
                    <p>2020/09/08</p>
                </div>
                <div class="title">
                    <h3>
                        <a routerLink="/news/news-slug">
                            Mes esame entuziastingų profesionalų komanda
                        </a>
                    </h3>
                </div>
                <div class="excerpt">
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita.</p>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad error tenetur accusamus quo nostrum saepe mollitia ex, distinctio adipisci minus.</p>
                </div>
                <div class="read-more">
                    <p><a routerLink="/news/news-slug">skaityti daugiau →</a></p>
                </div>
            </div>
        </div>
        <div class="row other-news">
            <div class="col-12">
                <h2 class="block-title">Mūsų populiariausios naujienos</h2>
            </div>
            <div class="col-12 articles">
                <app-news-post-block-small *ngFor="article of articles"></app-news-post-block-small>
            </div>
        </div>
        <div class="row pagination">
            <div class="col-12 buttons">
                <button class="prev">
                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Arrow Back</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/></svg>
                </button>
                <ul class="pages">
                    <li><button>1</button></li>
                    <li><button class="active">2</button></li>
                    <li><button>3</button></li>
                </ul>
                <button class="next">
                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Arrow Forward</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/></svg>
                </button>
            </div>
        </div>
    </div>
</div>
