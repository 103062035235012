<header class="information-page-header" style="--background: url('/assets/img/pages/services/services-header.jpg')">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Mūsų paslaugos</h1>
            </div>
        </div>
    </div>
</header>

<div class="info-page content-wrapper buildings-bottom services-page">
    <div class="container">
        <div class="row services-about">
            <div class="col-12">
                <h2 class="block-title">Mūsų siūlomos paslaugos</h2>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-12 description">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita.</p>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-12 offset-xl-1 offset-lg-0 listed-info">
                <ul>
                    <li>
                        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Checkmark</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M416 128L192 384l-96-96'/></svg>
                        <p>Lorem ipsum dolor sit amet.</p>
                    </li>
                    <li>
                        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Checkmark</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M416 128L192 384l-96-96'/></svg>
                        <p>Aliquid tenetur repudiandae doloremque facere.</p>
                    </li>
                    <li>
                        <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Checkmark</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M416 128L192 384l-96-96'/></svg>
                        <p>Lorem ipsum dolor, sit amet consectet repudiandae ratione officia saepe veniam deserunt sint.</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row services-benefits">
            <div class="col-12">
                <h2 class="block-title">Nauda</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 benefit">
                <div class="row">
                    <div class="col-lg-3 icon">
                        <span class="relt-icon-house7"></span>
                    </div>
                    <div class="col-lg-9 info">
                        <h3>Kodėl rinktis tai?</h3>
                        <p>Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 benefit">
                <div class="row">
                    <div class="col-lg-3 icon">
                        <span class="relt-icon-billboard"></span>
                    </div>
                    <div class="col-lg-9 info">
                        <h3>Kodėl rinktis tai?</h3>
                        <p>Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 benefit">
                <div class="row">
                    <div class="col-lg-3 icon">
                        <span class="relt-icon-search1"></span>
                    </div>
                    <div class="col-lg-9 info">
                        <h3>Kodėl rinktis tai?</h3>
                        <p>Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas. Tekstas apie mūsų deklaruojamą vertybę ir dar šis bei tas.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row services-block">
            <div class="col-12">
                <h2 class="block-title">Paslaugų sąrašas</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 service-block">
                <div class="service">
                    <div class="image">
                        <a routerLink="/services/some-service">
                            <img src="/assets/img/pages/services/service1.jpg" class="img-fluid" alt="image placeholder">
                        </a>
                    </div>
                    <div class="info">
                        <h3><a routerLink="/services/some-service">NT vertinimas</a></h3>
                        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui sit, nostrum cum quisquam dolorem enim!</p>
                        <p class="read-more"><a routerLink="/services/some-service">skaityti daugiau</a></p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 service-block">
                <div class="service">
                    <div class="image">
                        <a routerLink="/services/some-service">
                            <img src="/assets/img/pages/services/service2.jpg" class="img-fluid" alt="image placeholder">
                        </a>
                    </div>
                    <div class="info">
                        <h3><a routerLink="/services/some-service">Dokumentų ruošimas</a></h3>
                        <p class="description">Dolor consectetur adipisicing consectetur sit amet nostrum cum quisquam consectetur adipisicing elit. Qui sit, nostrum cum quisquam dolorem enim!</p>
                        <p class="read-more"><a routerLink="/services/some-service">skaityti daugiau</a></p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 service-block">
                <div class="service">
                    <div class="image">
                        <a routerLink="/services/some-service">
                            <img src="/assets/img/pages/services/service3.jpg" class="img-fluid" alt="image placeholder">
                        </a>
                    </div>
                    <div class="info">
                        <h3><a routerLink="/services/some-service">Konsultavimas</a></h3>
                        <p class="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui sit, nostrum cum quisquam dolorem enim!</p>
                        <p class="read-more"><a routerLink="/services/some-service">skaityti daugiau</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
