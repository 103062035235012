import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { NewsComponent } from './pages/news/news.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { CookieConsentComponent } from './shared/components/cookie-consent/cookie-consent.component';
import { NewsPostComponent } from './pages/news/news-post/news-post.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AdvertsComponent } from './pages/adverts/adverts.component';
import { AdvertDetailsComponent } from './pages/adverts/advert-details/advert-details.component';
import { AdvertCreationComponent } from './pages/adverts/advert-creation/advert-creation.component';
import { ServicesComponent } from './pages/services/services.component';
import { ServiceDetailsComponent } from './pages/services/service-details/service-details.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { RegisterComponent } from './pages/user/register/register.component';
import { LoginComponent } from './pages/user/login/login.component';
import { HomeMainSliderComponent } from './pages/home/home-main-slider/home-main-slider.component';
import { HomeMostPopularComponent } from './pages/home/home-most-popular/home-most-popular.component';
import { HomeNewestAndServicesComponent } from './pages/home/home-newest-and-services/home-newest-and-services.component';
import { HomePartnersComponent } from './pages/home/home-partners/home-partners.component';
import { NewsPostBlockSmallComponent } from './pages/news/news-post-block-small/news-post-block-small.component';
import { AuthenticationFormComponent } from './pages/user/authentication-form/authentication-form.component';
import { AdvertBox1Component } from './pages/adverts/advert-box1/advert-box1.component';
import { FaqComponent } from './pages/faq/faq.component';
import { AdvertCreationStepObjectTypeComponent } from './pages/adverts/advert-creation/advert-creation-step-object-type/advert-creation-step-object-type.component';
import { AdvertCreationStepObjectInformationComponent } from './pages/adverts/advert-creation/advert-creation-step-object-information/advert-creation-step-object-information.component';
import { AdvertCreationStepAdditionalInformationComponent } from './pages/adverts/advert-creation/advert-creation-step-additional-information/advert-creation-step-additional-information.component';
import { AdvertCreationStepPaymentComponent } from './pages/adverts/advert-creation/advert-creation-step-payment/advert-creation-step-payment.component';
import { AdvertCreationStepEndingComponent } from './pages/adverts/advert-creation/advert-creation-step-ending/advert-creation-step-ending.component';
import { AdvertBox2Component } from './pages/adverts/advert-box2/advert-box2.component';
import { SliderNavigationButtonsComponent } from './shared/components/slider-navigation-buttons/slider-navigation-buttons.component';
import { UserProfileComponent } from './pages/user/user-profile/user-profile.component';
import { UserMyAdvertsComponent } from './pages/user/user-my-adverts/user-my-adverts.component';
import { UserPaymentHistoryComponent } from './pages/user/user-payment-history/user-payment-history.component';
import { UserMyFavoritesComponent } from './pages/user/user-my-favorites/user-my-favorites.component';
import { UserProfileNavigationBlockComponent } from './pages/user/user-profile-navigation-block/user-profile-navigation-block.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AboutUsComponent,
        NewsComponent,
        NavbarComponent,
        FooterComponent,
        CookieConsentComponent,
        NewsPostComponent,
        PrivacyPolicyComponent,
        AdvertsComponent,
        AdvertDetailsComponent,
        AdvertCreationComponent,
        ServicesComponent,
        ServiceDetailsComponent,
        ContactUsComponent,
        RegisterComponent,
        LoginComponent,
        HomeMainSliderComponent,
        HomeMostPopularComponent,
        HomeNewestAndServicesComponent,
        HomePartnersComponent,
        NewsPostBlockSmallComponent,
        AuthenticationFormComponent,
        AdvertBox1Component,
        FaqComponent,
        AdvertCreationStepObjectTypeComponent,
        AdvertCreationStepObjectInformationComponent,
        AdvertCreationStepAdditionalInformationComponent,
        AdvertCreationStepPaymentComponent,
        AdvertCreationStepEndingComponent,
        AdvertBox2Component,
        SliderNavigationButtonsComponent,
        UserProfileComponent,
        UserMyAdvertsComponent,
        UserPaymentHistoryComponent,
        UserMyFavoritesComponent,
        UserProfileNavigationBlockComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
