import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-advert-box2',
    templateUrl: './advert-box2.component.html',
    styleUrls: ['./advert-box2.component.scss'],
})
export class AdvertBox2Component implements OnInit {

    quickViewActive = false;

    constructor() {}

    ngOnInit(): void {}

    toggleQuickView(): void {
        this.quickViewActive = !this.quickViewActive;
    }

}
